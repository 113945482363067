import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';
import { LuminaInputProps } from './Input';
import { ChangeEvent, KeyboardEvent, useEffect, useState, WheelEvent } from 'react';

export const shouldSame = (value: string, comparable: string, message: string): boolean | string =>
    value === comparable || message;

const TextInput = ({
    label,
    endAdornment,
    startAdornment,
    type,
    placeholder,
    margin,
    name,
    onChange,
    onBlur,
    onKeyDown,
    value,
    error,
    helper,
    disabled
}: LuminaInputProps): JSX.Element => {
    const addCommas = (num: string): string =>
        num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const removeNonNumeric = (num: string): string => num?.toString().replace(/[^0-9]/g, '');
    const [thousandSeparatorValue, setThousandSeparatorValue] = useState(
        addCommas(removeNonNumeric((value as string) ?? ''))
    );

    const handleThousandSeparatorChange = (event?: ChangeEvent): void => {
        setThousandSeparatorValue(
            addCommas(
                removeNonNumeric((event?.target as HTMLTextAreaElement | HTMLInputElement).value)
            )
        );
    };

    const handleThousandSeparatorKeyboard = (event?: KeyboardEvent): void => {
        setThousandSeparatorValue(
            addCommas(
                removeNonNumeric((event?.target as HTMLTextAreaElement | HTMLInputElement).value)
            )
        );
    };

    /**
     * This useEffect needed to listen changes on thousandSeparatorValue if type is thousandSeparator.
     * because even we show user value like 1,000,000 but at the end we still want to give backend value
     * like 1000000 without doing any pre processing outside this form component to hide unknown unknown
     * and complexity.
     */
    useEffect(() => {
        if (onChange !== undefined) {
            onChange({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                target: {
                    value: thousandSeparatorValue.replace(new RegExp(`,`, 'g'), '')
                }
            });
        }
    }, [thousandSeparatorValue]);

    const computeError = (): string => {
        if (error?.type === 'required') {
            return error.message ?? 'Field ini wajib diisi';
        }

        if (error?.type === 'minValue') {
            return error.message ?? 'Field ini harus diisi dengan angka lebih besar';
        }

        if (error?.type === 'maxValue') {
            return error.message ?? 'Field ini harus diisi dengan angka lebih kecil';
        }

        if (error?.type === 'shouldSame') {
            return error.message ?? 'Field ini tidak sama dengan field sebelumnya';
        }

        return 'Field ini tidak sesuai format';
    };

    return (
        <div className={margin ?? 'my-2'}>
            <InputLabel>
                <Typography variant="14-regular" className={disabled ? 'text-greyscale-200' : ''}>
                    {label}
                </Typography>
            </InputLabel>
            <InputBase
                onWheel={(e: WheelEvent<HTMLDivElement>) => (e.target as HTMLElement).blur()}
                endAdornment={<div style={{ paddingBottom: '2px' }}>{endAdornment}</div>}
                startAdornment={<div style={{ paddingBottom: '2px' }}>{startAdornment}</div>}
                type={type === 'thousandSeparator' ? 'text' : type}
                name={name}
                onChange={type === 'thousandSeparator' ? handleThousandSeparatorChange : onChange}
                onBlur={type === 'thousandSeparator' ? handleThousandSeparatorChange : onBlur}
                onKeyDown={
                    type === 'thousandSeparator' ? handleThousandSeparatorKeyboard : onKeyDown
                }
                value={type === 'thousandSeparator' ? thousandSeparatorValue : value}
                disabled={disabled}
                placeholder={placeholder ?? 'placeholder'}
                className={error && 'error'}
            />
            <Typography variant="12-regular" className="text-greyscale-500" component="p">
                {helper}
            </Typography>
            {error && (
                <Typography variant="12-regular" className="text-fire-400" component="p">
                    {computeError()}
                </Typography>
            )}
        </div>
    );
};

export default TextInput;
