import Button, { ButtonProps } from '@mui/material/Button';
import Link from 'next/link';

export type LuminaButtonProps = ButtonProps & {
    className?: string;
    href?: string;
    target?: string;
};

const LuminaButton = ({
    className,
    href,
    target,
    children,
    ...props
}: LuminaButtonProps): JSX.Element => {
    if (href && target) {
        return (
            <a href={href} target={target} className={className}>
                <Button {...props} fullWidth>
                    {children}
                </Button>
            </a>
        );
    }

    if (href) {
        return (
            <Link href={href}>
                <div className={className}>
                    <Button {...props} fullWidth>
                        {children}
                    </Button>
                </div>
            </Link>
        );
    }

    return (
        <div className={className}>
            <Button {...props} fullWidth>
                {children}
            </Button>
        </div>
    );
};

export default LuminaButton;
