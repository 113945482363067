import LoginForm from 'authentication/components/LoginForm';
import React from 'react';

const LoginContainer = (): JSX.Element => {
    return (
        <div className="min-h-screen w-screen flex flex-col-reverse md:flex-row">
            <div className="w-full md:w-5/12 min-h-[70vh] md:min-h-screen flex md:pt-16 md:pb-8 md:px-24 py-8 px-4 flex-col items-start justify-between">
                <div className="w-full">
                    <img
                        src="https://djhutyrz7bno6.cloudfront.net/assets/full_logo.svg"
                        alt="lumina-logo"
                        className="logo-login hidden md:flex"
                    />
                    <div style={{ height: '64px' }} className="hidden md:flex" />
                    <LoginForm />
                </div>
            </div>
            <div
                className="w-full h-[30vh] md:w-7/12 md:min-h-screen"
                style={{
                    backgroundImage:
                        'linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%), url(https://djhutyrz7bno6.cloudfront.net/assets/ats_cover.jpg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}>
                <div className="w-full h-full p-4 flex md:hidden flex-col justify-end">
                    <img
                        src="https://djhutyrz7bno6.cloudfront.net/assets/full_logo_white.png"
                        alt="lumina-logo"
                        className="h-10 w-fit flex md:hidden"
                    />
                </div>
            </div>
        </div>
    );
};

export default LoginContainer;
