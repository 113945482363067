import Typography from '@mui/material/Typography';
import TextInput from 'core/components/Input/TextInput';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useLoginMutation } from 'authentication/redux/api/authApi';
import CircularProgress from '@mui/material/CircularProgress';
import LuminaButton from 'core/components/Button';
import { EyeEmpty, EyeOff } from 'iconoir-react';

const LoginForm = (): JSX.Element => {
    const [isShowPassword, setShowPassword] = useState<boolean>(false);
    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({
        defaultValues: {} as LoginInputData
    });
    const [login, { isLoading }] = useLoginMutation();

    const onSubmit = (data: LoginInputData): void => {
        login(data);
    };

    return (
        <div>
            <Typography variant="32-bold" className="hidden md:flex" component={'h1'}>
                Login
            </Typography>
            <Typography variant="14-regular" className="hidden md:flex">
                Masukkan nomor HP / email dan password Anda untuk masuk ke dalam sistem.
            </Typography>
            <div className="h-8 hidden md:flex" />
            <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="identifier"
                    control={control}
                    rules={{ required: 'No HP wajib diisi' }}
                    render={({ field }) => (
                        <TextInput
                            label="Nomor HP/Email"
                            placeholder="Masukkan nomor HP/Email"
                            type="text"
                            {...field}
                            error={errors.identifier}
                        />
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    rules={{ required: 'Password wajib diisi' }}
                    render={({ field }) => (
                        <TextInput
                            label="Password"
                            placeholder="Masukkan password"
                            {...field}
                            type={isShowPassword ? 'text' : 'password'}
                            error={errors.password}
                            endAdornment={
                                isShowPassword ? (
                                    <EyeOff
                                        onClick={() => setShowPassword(false)}
                                        className="text-greyscale-400 cursor-pointer"
                                    />
                                ) : (
                                    <EyeEmpty
                                        onClick={() => setShowPassword(true)}
                                        className="text-greyscale-400 cursor-pointer"
                                    />
                                )
                            }
                        />
                    )}
                />
                <div className="h-4" />
                <LuminaButton fullWidth type="submit" variant="contained">
                    {isLoading ? (
                        <CircularProgress size={25} color="success" />
                    ) : (
                        <span>Masuk</span>
                    )}
                </LuminaButton>
            </form>
        </div>
    );
};

export default LoginForm;
